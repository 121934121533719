export default {
    components: {},
    data() {
        return {
            data: {},
            error: {}
        }
    },
    async mounted() {


    },
    methods: {
        async login(e) {
            e.preventDefault()
            await this.$lionheart.post('v2/auth/login/admin', this.data)
                .then(async (response) => {

                    let result = response.data;

                        await this.$storage.set('auth_admin', result.data)
                        location.href = "/admin/";
                        this.error = {};

                }).catch((error) => {

                   this.error = error.response.data;
                });


        }
    }
}
